.ck-markdown-editor {
    p {
        font-size: 1rem;
    }

    h1 {
        color: var(--bs-primary);
        font-size: 1.75rem;
        font-weight: 500;
    }

    h2 {
        color: var(--bs-primary);
        font-size: 1.5rem;
        font-weight: 400;
    }

    h3 {
        color: var(--bs-primary);
        font-size: 1.25rem;
        font-weight: 300;
    }

    border: 1px solid rgb(123, 128, 154);
    border-radius: 6px;
    min-height: 400px;
}

.mdx-editor-toolbar {
    background-color: var(--bs-primary);
}
